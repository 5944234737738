/* Custom Modal Width */
.custom-modal .modal-dialog {
    max-width: 800px; /* Adjust width as needed */
    width: 90%; /* Responsive width */
  }
  
  /* Ensure modal is properly centered */
  .custom-modal .modal-content {
   width: 800px;
    border-radius: 3px;
  }
  
  /* Responsive Styling */
  @media (max-width: 768px) {
    .custom-modal .modal-dialog {
      width: 90%; /* Smaller width on mobile */
    }
  }
  